import { GetServerSideProps, NextPage } from 'next'
import { useRouter } from 'next/router'
import React, { memo } from 'react'
import { useBackendQuery } from '@thirstycamel/ui'

import ProductListEntity from '@ts/core/src/modules/productlist/productlist.entity'
import CategoryGroupEntity from '@ts/core/src/modules/category/categorygroup.entity'

import { SearchResults } from '../../components/SearchResults/SearchResults'
import { Banner } from '../../components/Banner/Banner'
import LayoutMain from '../../layout/LayoutMain'
import { backend } from '../../utils/backend'
import { setCookie } from 'nookies'
import { redirectOnRewrite } from '../../utils/redirectOnRewrite'

const Group: NextPage = () => {
  const router = useRouter()

  const [group] = [router.query.group] as string[]

  const { isFetching, error, data } = useBackendQuery<ProductListEntity>([
    `product-lists/slug/${group}`,
  ])

  const { data: categoryGroupData } = useBackendQuery<CategoryGroupEntity>([
    `categorygroup/${group}`,
  ])

  const groupName = group
    .split('-')
    .map(s => `${s[0]?.toUpperCase()}${s.slice(1)}`)
    .join(' ')

  const isSpecial = group === 'specials' || !!router.query.specials
  const isEverydayValue = group === 'everyday-value'

  return (
    <LayoutMain
      title={data?.title || groupName}
      meta_title={
        isEverydayValue
          ? 'Save Big on Everday Value Offers on Alcohol | Thirsty Camel'
          : isSpecial
          ? 'Beer, Vodka, Rum & More on Sale | Specials| Thirsty Camel'
          : categoryGroupData?.meta_title
      }
      meta_description={categoryGroupData?.meta_description}
      noContainer
    >
      {data?.id && (
        <Banner
          heading={data?.title}
          description={data?.description}
          src={data?.bannerImage?.filename}
          link={data?.bannerLink}
        />
      )}
      <SearchResults
        totals
        key={`${group}-${isEverydayValue ? 'everyday-value' : isSpecial ? 'special' : ''}`}
        seo_description={categoryGroupData ? categoryGroupData.seo_description : ''}
      />
    </LayoutMain>
  )
}

export const getServerSideProps: GetServerSideProps = async context => {
  const { group: slug } = context.params

  await redirectOnRewrite(context)

  try {
    const store = await backend(`/stores/${slug}`)

    if (store) {
      setCookie(context, 'selected_store', slug as string, { path: '/' })
    }

    const { res } = context

    res.statusCode = 302
    res.setHeader('Location', '/')
  } catch (e) {}

  return { props: {} }
}

export default memo(Group)
