import React, { useMemo } from 'react'
import { Box, BoxProps, Stack, Image, Text, Heading } from '@thirstycamel/ui'
import reactStringReplace from 'react-string-replace'
import imageProxy from '../../utils/imageProxy'

export const LINK_TAG_REGEX = /\[\[(.*?)\]\]/gm

const addHyperlinksToDescriptions = (description: any) => {
  if (!description?.includes('[[') && !description?.includes(']]')) {
    return description
  }

  /**
   * find hypelinks indicated by [[string]] within the description var
   */

  /**
    top drops test hyperlink description absolute [[http://google.com.au]] or relative [[/terms-and-conditions]] lorem ipsum dolar set amen. vila se ante mallum val fei deus impa senta
   */

  let descriptionWithLinks = description

  const regexp = LINK_TAG_REGEX
  const capturedLinks = [...description.matchAll(regexp)].map(([fullMatch, link], i) => ({
    fullMatch,
    link,
  }))

  if (capturedLinks.length > 0) {
    capturedLinks.forEach(tag => {
      descriptionWithLinks = reactStringReplace(descriptionWithLinks, tag.fullMatch, (match, i) => {
        const [link, text] = tag.link?.split('|')

        return (
          <a href={link} {...link?.startsWith('http') ? { target: "_blank"} : {}}>
            <Text as="span" color={"pink.500"} fontWeight={'bold'} textDecoration={"underline"}>
              {text ?? link}
            </Text>
          </a>
        )
      })
    })
  }

  return descriptionWithLinks
}

export interface BannerProps extends BoxProps {
  heading: string
  description: string
  src: string
  link?: string
}

export const Banner = ({ heading, description, src, link, ...restProps }: BannerProps) => {
  return (
    <Box>
      <Stack spacing={4} py={8} textAlign="center">
        <Heading textTransform="uppercase" fontSize={['2xl', '3xl']}>
          {heading}
        </Heading>
        <Text color="blackAlpha.700">{addHyperlinksToDescriptions(description)}</Text>
      </Stack>
      {link ? (
        <a href={link} target="_blank">
          <Image src={imageProxy(src, { width: 2840 })} />
        </a>
      ) : (
        <Image src={imageProxy(src, { width: 2840 })} />
      )}
    </Box>
  )
}

export default Banner
